<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <Html :lang="head.htmlAttrs?.lang">
    <Head>
      <template v-for="link in head.link" :key="link.id">
        <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :content="meta.content" :property="meta.property" />
      </template>
    </Head>
  </Html>

  <NuxtPwaManifest />

  <v-app>
    <v-layout>
      <v-navigation-drawer
        v-model="leftDrawerOpen"
        :rail="miniDrawer && $vuetify.display.smAndUp"
        :permanent="miniDrawer && $vuetify.display.smAndUp"
        :color="currentTheme.dark ? 'tertiary' : currentThemeName === 'kanters' ? 'surface' : 'primary'"
        name="nav-drawer"
      >
        <template #prepend><layout-side-menu-header :user="currentUser" :rail="miniDrawer && $vuetify.display.smAndUp" @update:left-drawer-open="() => leftDrawerOpen = !leftDrawerOpen" /></template>
        <template #default><layout-side-menu :rail="miniDrawer && $vuetify.display.smAndUp" /></template>
        <template #append><layout-side-menu-footer :rail="miniDrawer && $vuetify.display.smAndUp" /></template>
      </v-navigation-drawer>

      <v-app-bar :color="currentThemeName === 'kanters' ? 'primary' : 'tertiary'" class="text-white">
        <v-app-bar-nav-icon v-if="$route.meta.back" icon="arrow_back" @click="() => $router.back()"/>
        <v-app-bar-nav-icon v-else-if="!leftDrawerOpen || (miniDrawer && $vuetify.display.smAndUp)" icon="menu" @click="() => leftDrawerOpen = !leftDrawerOpen"/>

        <v-toolbar-title v-if="breadcrumbs.length && $vuetify.display.mdAndUp">
          <v-breadcrumbs
            class="px-0 navigation-breadcrumbs" :items="breadcrumbs.map((breadcrumb, index, self) => ({
              ...breadcrumb,
              title: (self.length - 1) === index ? title : $i18n.t(breadcrumb.title),
              disabled: (self.length - 1) === index
            }))"
          />
        </v-toolbar-title>
        <v-toolbar-title v-else>{{ title }}</v-toolbar-title>

        <template #append>
          <v-dialog v-if="$vuetify.display.smAndDown" width="400">
            <template #activator="{ props }">
              <v-btn v-bind="props" icon="search"/>
            </template>

            <template #default="{ isActive }">
              <global-search
                variant="solo"
                single-line
                autofocus
                :placeholder="$t('search')"
                @select-asset="isActive.value = false"
              />
            </template>
          </v-dialog>

          <global-search
            v-else
            rounded
            variant="outlined"
            density="comfortable"
            class="mr-2"
            style="width: 300px;"
          />

          <v-menu>
            <template #activator="{ props }">
              <v-btn icon="add" v-bind="props"/>
            </template>

            <template #default>
              <add-menu />
            </template>
          </v-menu>

          <v-menu :close-on-content-click="false">
            <template #activator="{ props }">
              <v-btn :icon="isImpersonated ? 'supervised_user_circle': 'account_circle'" v-bind="props"/>
            </template>

            <template #default="{ isActive }">
              <header-menu @close="() => isActive.value = false" />
            </template>
          </v-menu>
        </template>

        <template v-if="tabs.length > 0" #extension>
          <v-tabs :key="tabs.join('-')" :model-value="$route.query.tab" :class="$vuetify.display.mdAndUp ? 'px-4' : ''" @update:model-value="tab => updateTab(tab as string)">
            <v-tab
              v-for="tab of tabs"
              :key="tab.value"
              :value="tab.value"
              :prepend-icon="(tab.icon && $te(tab.icon)) ? $t(tab.icon) : tab.icon"
              class="text-none"
              :disabled="tab.scope && !hasScope(organizationId, tab.scope)"
            >
              {{ $te(tab.label) ? $t(tab.label) : tab.label }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>

      <map-drawer v-bind="mapProps"/>

      <v-main scrollable name="main-content">
        <v-progress-linear v-if="currentUserPending || scopesPending" indeterminate class="position-absolute top-0" />
        <slot v-if="!currentUserPending && !scopesPending" />

        <snackbar-toaster :snackbars="snackbars" />
      </v-main>

      <v-theme-provider theme="dark">
        <v-bottom-navigation v-if="display.xs.value" grow bg-color="tertiary">
          <v-btn to="/dashboard">
            <v-icon>{{ $t("dashboard.icon") }}</v-icon>
            <span>Home</span>
          </v-btn>
          <v-btn to="/assets">
            <v-icon>{{ $t("assets.icon") }}</v-icon>
            <span>{{ $t("assets.title") }}</span>
          </v-btn>
          <v-btn to="/tickets">
            <v-icon>{{ $t("tickets.icon") }}</v-icon>
            <span>{{ $t("tickets.title") }}</span>
          </v-btn>
        </v-bottom-navigation>
      </v-theme-provider>
    </v-layout>
  </v-app>
</template>

<script setup lang="ts">
import { useTheme, useDisplay } from "vuetify"
import { jwtDecode } from "jwt-decode"

const { grantConsent } = useGtag()
const { themes, current: currentTheme, name: currentThemeName, global: globalTheme } = useTheme()
const display = useDisplay()
const $route = useRoute()
const $router = useRouter()
const $i18n = useI18n()

const userStore = useUserStore()
const authStore = useAuthStore()
const mainStore = useMainStore()
const { token, currentOrganization, isImpersonated } = storeToRefs(authStore)
const { snackbars } = storeToRefs(mainStore)

const { theme, miniDrawer, cookiePreferences } = useUserPreferences()

const leftDrawerOpen = ref(display.lgAndUp.value)
const mapProps = ref<Record<string, unknown>>({})

const { hasScope } = authStore
const mutateMapProps = (settings: Record<string, unknown>) => { mapProps.value = settings }

provide("mapProps", mapProps)
provide("mutateMapProps", mutateMapProps)

const { computedTitle: title } = useRouteTitle()

const organizationId = computed(() => {
  const organizationId = $route.meta.organizationId as string | undefined
  if (!organizationId) { return currentOrganization.value! }

  return organizationId
})

const head = useLocaleHead({
  addSeoAttributes: true
})

const breadcrumbs = computed<Array<BreadcrumbItem>>(() => $route.meta.breadcrumbs && Array.isArray($route.meta.breadcrumbs) ? $route.meta.breadcrumbs : [])

const { tabs, updateTab } = useTabs()

watch(() => $route.meta.tabs, (newTabs) => {
  tabs.value = (Array.isArray(newTabs) && newTabs) || []
}, { immediate: true, deep: true })

const { data: currentUser, pending: currentUserPending } = useAsyncData(() => userStore.getCurrentUser(), { immediate: true })
const { pending: scopesPending } = useAsyncData(() => authStore.fetchAllOrganizationScopes(), { immediate: true })

watch(cookiePreferences, () => {
  if (cookiePreferences.value.includes(CookiePreferences.TRACKING)) {
    grantConsent()
  }
}, { immediate: true, deep: true })


watch(theme, () => {
  if (!theme.value) {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches
    const decodedToken = !prefersDark && token.value ? jwtDecode<TokenBody>(token.value) : undefined

    globalTheme.name.value = prefersDark ? 'dark' : decodedToken?.domain === DomainType.CIVIL ? 'civilLight' : 'light'
  }
  if (theme.value && themes.value[theme.value]) {
    globalTheme.name.value = theme.value
  }
}, { immediate: true })

onMounted(async () => {
  if (currentTheme.value.dark) {
    import("devextreme/dist/css/dx.material.blue.dark.compact.css")
  } else {
    import("devextreme/dist/css/dx.material.blue.light.compact.css")
  }
})
</script>

<style lang="scss">
.v-navigation-drawer__content {
  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    z-index: 999;
    position: absolute;

    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
    }
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.navigation-breadcrumbs .v-breadcrumbs-item--disabled {
  opacity: inherit;
}
</style>