<template>
  <v-card
    class="rounded-0"
    :class="rail ? 'rail-header' : ''"
    :variant="
      rail
        ? 'text'
        : theme.current.value.dark
          ? 'elevated'
          : 'tonal'
    "
    :style="{
      background: rail
        ? undefined
        : theme.current.value.dark
          ? 'linear-gradient(45deg, rgba(255,255,255,0.1) 20%, rgba(255,255,255,0) 100%)'
          : 'linear-gradient(45deg, rgba(255,255,255,0) 20%, rgba(255,255,255,0.2) 100%)',
    }"
  >
    <template #title>
      <nuxt-link v-if="!rail" to="/dashboard">
        <v-img class="logo-image" :src="$vuetify.theme.name === 'kanters' ? '/kanters.png' : '/logo-wit.png'" alt="SAM Logo" height="40px">
        </v-img>
      </nuxt-link>

      <v-avatar v-else image="/logo-icon-wit.svg" />
    </template>

    <template #append>
      <v-app-bar-nav-icon v-if="!rail" variant="text" icon="chevron_left" @click="() => emit('update:leftDrawerOpen')"></v-app-bar-nav-icon>
    </template>

    <v-list v-if="!rail">
      <inner-loading :showing="pending"></inner-loading>

      <v-list-item v-if="pending" :title="$t('loading')" :subtitle="$t('loading')" prepend-icon="help_center"></v-list-item>

      <v-menu v-else-if="organizations!.length > 1 || isSuperAdmin" :close-on-content-click="false">
        <template #activator="{ props }">
          <v-list-item
            v-bind="props"
            :title="user ? `${user?.firstName} ${user?.lastName}` : $t('unknown')"
            :prepend-icon="currentOrganizations?.[0]?.type ? $t(`organizationIcon.${currentOrganizations[0].type}`) : 'help_center'"
            lines="two"
            density="comfortable"
          >
            <template #subtitle>
              <span>{{ currentOrganizations?.[0]?.description }}</span>
            </template>
          </v-list-item>
        </template>

        <template #default>
          <v-card>
            <v-card-title>{{ $t('chooseOrganization') }}</v-card-title>

            <v-card-text>
              <organization-list hide-admin />
            </v-card-text>
          </v-card>
        </template>
      </v-menu>

      <v-list-item
        v-else
        :title="user ? `${user?.firstName} ${user?.lastName}` : $t('unknown')"
        :prepend-icon="currentOrganizations?.[0]?.type ? $t(`organizationIcon.${currentOrganizations[0].type}`) : 'help_center'"
        lines="two"
        density="comfortable"
      >
        <template #subtitle>
          <span>{{ currentOrganizations?.[0]?.description ?? $t('loading') }}</span>
        </template>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script setup lang="ts">
import { useTheme } from "vuetify"

const emit = defineEmits(["update:leftDrawerOpen"])
const props = defineProps<{ rail?: boolean, user?: User | null }>()
const { rail, user } = toRefs(props)

const theme = useTheme()
const organizationStore = useOrganizationStore()
const authStore = useAuthStore()
const { currentOrganizations } = storeToRefs(organizationStore)
const { isSuperAdmin } = storeToRefs(authStore)

const { data: organizations, pending } = useLazyAsyncData("organizations", organizationStore.getOrganizations, { default: () => [], immediate: true })
</script>

<style lang="scss">
.logo-image .v-img__img--contain {
  object-position: left;
}

.rail-header {
  .v-card-item {
    height: 64px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    .v-card-item__content {
      overflow: visible;
    }
  }
}
</style>