<template>
  <v-autocomplete
    ref="searchField"
    :model-value="searchFieldValue"
    :search="searchString ?? undefined"
    :label="$t('search')"
    hide-details
    prepend-inner-icon="search"
    :items="searchResults"
    :item-title="(item: SearchAsset) => `${item.key} | ${item.description}`"
    item-value="_id"
    clearable
    menu-icon=""
    :loading="searchPending"
    :custom-filter="() => true"
    @update:model-value="(assetId: string) => selectAsset(assetId)"
    @update:search="debounceSearch"
    @click:clear="() => { searchString = ''; searchResults = [] }"
    @click="() => { searchString = ''; searchResults = [] }"
  >
    <template #item="{ props, item }">
      <v-list-item v-bind="props" class="align-start">
        <template #append>
          <v-avatar
            rounded
            class="elevation-3"
            size="34px"
          >
            <v-avatar
              :color="item.raw.category?.marker.color"
              class="font-weight-medium text-body-2"
              rounded
              size="30px"
            >
              {{ item.raw.category?.marker.description }}
            </v-avatar>
          </v-avatar>
        </template>

        <template #title>
          <div class="ellipsis overflow-hidden" style="max-width: 420px;">{{ item.raw.key }} | {{ item.raw.description }}</div>
        </template>

        <template #subtitle>
          <div>{{ item.raw.template }}</div>
          <div class="ellipsis">{{ addressDisplay(item.raw.location) }}</div>
          <div v-if="organizations.length !== 1">{{ item.raw.organization.description }}</div>
        </template>
      </v-list-item>
    </template>

    <template #no-data>
      <v-list-item v-if="!searchPending && searchString && !searchResults.length" :subtitle="$t('noResults')"/>
      <v-list-item v-else-if="!searchPending && !searchString && !filteredVisitedAssets.length" :subtitle="$t('typeToSearch')"/>
      <v-list-item v-else-if="searchPending" :subtitle="$t('loading')"/>

      <template v-else-if="!searchPending && !searchString && filteredVisitedAssets.length">
        <v-list-subheader>{{ $t('recentlySearched') }}</v-list-subheader>
        <v-list-item v-for="asset in filteredVisitedAssets" :key="asset._id" @click="selectAsset(asset._id)">
          <template #append>
          <v-avatar
            rounded
            class="elevation-3"
            size="34px"
          >
            <v-avatar
              :color="asset.category?.marker.color"
              class="font-weight-medium text-body-2"
              rounded
              size="30px"
            >
              {{ asset.category?.marker.description }}
            </v-avatar>
          </v-avatar>
        </template>
        <template #title>
          <div class="ellipsis overflow-hidden" style="max-width: 420px;">{{ asset.key }} | {{ asset.description }}</div>
        </template>
        <template #subtitle>
          <div>{{ asset.template }}</div>
          <div class="ellipsis">
            {{ addressDisplay(asset.location) }}
          </div>
        </template>
        </v-list-item>

        <v-card-actions class="pa-0">
          <v-btn block color="primary" @click="recentlyVisitedAssets = []">{{ $t('clearHistory') }}</v-btn>
        </v-card-actions>
      </template>
    </template>
  </v-autocomplete>
</template>

<script setup lang="ts">
import debounce from "lodash-es/debounce"
import type { VAutocomplete } from "vuetify/components"

const $router = useRouter()
const assetStore = useAssetStore()
const authStore = useAuthStore()
const { organizations } = storeToRefs(authStore)

const emit = defineEmits<{
  (e: 'selectAsset', assetId: string | null): void
}>()

const searchField = ref<InstanceType<typeof VAutocomplete>>()
const searchString = ref<string | null>()
const searchPending = ref(false)
const searchResults = ref<Array<SearchAsset>>([])
const searchFieldValue = computed(() => null)

const recentlyVisitedAssets = useSessionStorage<Array<SearchAsset>>("sam::recentlyVisited", () => [], { deep: true })

const filteredVisitedAssets = computed(() => recentlyVisitedAssets.value.filter(asset =>  organizations.value.includes(asset.organization._id)))

const debounceSearch = debounce((val: string | undefined | null) => search(val), DEFAULT_DEBOUNCE_TIME * 1.5)

const search = async (val: string | undefined | null) => {
  if (!val) return

  searchPending.value = true
  searchString.value = val

  try {
    if (val) {
      searchResults.value = await assetStore.searchAssets(val)
    } else {
      searchResults.value = []
    }
  } catch (e) {
    console.error(e)
  } finally {
    searchPending.value = false
  }
}

const addRecentlyVisitedAsset = (asset: SearchAsset) => {
  recentlyVisitedAssets.value.unshift(asset)

  recentlyVisitedAssets.value = recentlyVisitedAssets.value
    .filter((asset, index, self) => self.findIndex((a) => a._id === asset._id) === index)
    .slice(0, 10)
}

const selectAsset = async (assetId: string | null) => {
  if (!assetId) return

  const asset = searchResults.value.find(item => item._id === assetId) ?? recentlyVisitedAssets.value.find(item => item._id === assetId)
  if (!asset) return

  addRecentlyVisitedAsset(asset)
  searchString.value = ''
  searchResults.value = []
  searchField.value?.blur()
  emit('selectAsset', assetId)
  await $router.push(`/assets/${asset._id}`)
}

const addressDisplay = (location: SearchAsset["location"]) => {
  const streetLine = location.street && location.houseNumber ? `${location.street} ${location.houseNumber}` : location.street
  const cityLine = location.city && location.zipCode ? `${location.zipCode} ${location.city}` : location.city
  const areaLine = location.area

  return [streetLine, cityLine, areaLine].filter(Boolean).join(", ")
}
</script>
