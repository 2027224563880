<template>
  <v-list density="comfortable" :class="rail ? 'pt-0' : undefined">
    <side-menu-item v-for="menuItem in primaryMenuList" :key="menuItem.title" :rail="rail" :menu-item="menuItem" />

    <side-menu-item v-if="hasSharedAssets" :rail="rail" :menu-item="sharedMenuItem" />

    <v-divider class="my-2"></v-divider>

    <side-menu-item v-for="menuItem in secondaryMenuList" :key="menuItem.title" :rail="rail" :menu-item="menuItem" />
  </v-list>
</template>

<script setup lang="ts">
import sortBy from "lodash-es/sortBy"
import type { MenuItem } from "./SideMenuItem.vue"

defineProps<{ rail?: boolean }>()

const assetStore = useAssetStore()
const sharedStore = useSharedStore()
const authStore = useAuthStore()
const settingsStore = useSettingsStore()

const { hasMultipleOrganizations } = storeToRefs(authStore)
const { dataQualityModuleEnabled, supplierModuleEnabled } = storeToRefs(settingsStore)


const { hasOrganizationWithScope } = authStore

const { data: assetCategories } = useLazyAsyncData("used-categories", async () => {
  return hasMultipleOrganizations.value
    ? []
    : sortBy(await assetStore.getUsedCategories(), "description")
}, {
  default: () => [] as Array<AssetCategory>,
  immediate: true,
})

const { data: hasSharedAssets } = useLazyAsyncData("has-shared-assets", () => sharedStore.hasSharedAssets(), { default: () => false })

const primaryMenuList = computed<Array<MenuItem>>(() => [
  {
    icon: "dashboard.icon",
    title: "dashboard.title",
    to: "/dashboard",
  },
  {
    icon: "assets.icon",
    title: "assets.title",
    to: `/assets?tab=LIST&clearFilters`,
    ...!hasMultipleOrganizations.value && {
      children: assetCategories.value!.map((category) => ({
        iconLabel: category.marker.description,
        color: category.marker.color,
        title: category.description,
        roundedAvatar: true,
        to: `/assets?&tab=LIST&fr_category=${ encodeURIComponent(JSON.stringify([category._id])) }`,
      }))
    },
  },
  {
    icon: "tickets.icon",
    title: "tickets.title",
    to: "/tickets?clearFilters&fr_createdAt=valDefault",
    disabled: [
      hasOrganizationWithScope(AuthScope.CAN_VIEW_INSPECTIONS),
      hasOrganizationWithScope(AuthScope.CAN_VIEW_MALFUNCTIONS),
      hasOrganizationWithScope(AuthScope.CAN_VIEW_ACTIONS),
    ].every((x) => !x),
    children: [
      {
        icon: "inspections.icon",
        color: "inspections.color",
        title: "inspections.title",
        to: `/tickets?fr_type=${ encodeURIComponent(JSON.stringify([TicketType.INSPECTION])) }&fr_createdAt=valDefault`,
        disabled: !hasOrganizationWithScope(AuthScope.CAN_VIEW_INSPECTIONS),
      },
      {
        icon: "malfunctions.icon",
        color: "malfunctions.color",
        title: "malfunctions.title",
        to: `/tickets?fr_type=${ encodeURIComponent(JSON.stringify([TicketType.MALFUNCTION])) }&fr_createdAt=valDefault`,
        disabled: !hasOrganizationWithScope(AuthScope.CAN_VIEW_MALFUNCTIONS),
      },
      {
        icon: "actions.icon",
        color: "actions.color",
        title: "actions.title",
        to: `/tickets?fr_type=${ encodeURIComponent(JSON.stringify([TicketType.ACTION])) }&fr_createdAt=valDefault`,
        disabled: !hasOrganizationWithScope(AuthScope.CAN_VIEW_ACTIONS),
      },
    ],
  },
  {
    icon: "finance.icon",
    title: "finance.title",
    to: "/finance",
    hideDropdownTitle: true,
    children: [
      {
        title: "finance.myp.title",
        to: "/finance/myp",
        disabled: !hasOrganizationWithScope(AuthScope.CAN_VIEW_FINANCES_BUDGET_OVERVIEW),
      },
      {
        title: "finance.tickets.title",
        to: "/finance/tickets",
        disabled: !hasOrganizationWithScope(AuthScope.CAN_VIEW_FINANCES_COSTS_AND_LABOUR_OVERVIEW),
      },
      {
        title: "finance.contracts.title",
        to: "/finance/contracts",
      },
      ...supplierModuleEnabled.value ? [{
        title: "finance.costSpecifications.title",
        to: "/finance/cost-specifications",
        disabled: !hasOrganizationWithScope(AuthScope.CAN_VIEW_FINANCES_COSTS_AND_LABOUR_OVERVIEW),
      }] : [],
    ],
  },
  {
    icon: "analytics.icon",
    title: "analytics.title",
    to: "/analytics",
    hideDropdownTitle: true,
    children: [
      {
        title: "risk.analysis",
        to: "/analytics/risk",
        disabled: !hasOrganizationWithScope(AuthScope.CAN_VIEW_ANALYSIS_RISK_OVERVIEW),
      },
      {
        title: "inspections.analysis",
        to: "/analytics/inspections",
        disabled: !hasOrganizationWithScope(AuthScope.CAN_VIEW_ANALYSIS_INSPECTION_OVERVIEW),
      },
      ...dataQualityModuleEnabled.value ? [{
        title: "analytics.dataQuality.title",
        to: "/analytics/data-quality",
        disabled: !hasOrganizationWithScope(AuthScope.ADMIN),
      }] : [],
    ],
  },
  {
    icon: "overviews.icon",
    title: "overviews.title",
    to: "/overviews",
  }
])

const sharedMenuItem: MenuItem = {
  icon: "shared.icon",
  title: "shared.title",
  to: "/shared",
  hideDropdownTitle: true,
  children: [
    {
      title: "shared.assets.title",
      to: "/shared/assets",
    },
    {
      title: "shared.tickets.title",
      to: "/shared/tickets",
    },
  ],
}

const secondaryMenuList = ref<Array<MenuItem>>([
  {
    icon: "support.icon",
    title: "support.title",
    to: "/support",
  },
  {
    icon: "settings.icon",
    title: "settings.title",
    to: "/settings",
  },
  ...hasOrganizationWithScope(AuthScope.CAN_VIEW_CHANGELOGS_OVERVIEW) ? [{
    icon: "mutationLog.icon",
    title: "mutationLog.title",
    to: "/mutation-log",
  }] : [],

])
</script>
